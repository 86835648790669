import MIT from "../../static/MIT.png";
import UNSW from "../../static/UNSW.png";

export default [
  {
    index: 1,
    organization: "Manukau Institute of Technology",
    logo: MIT,
    degree: "Graduate Diploma in Software and Web Development",
    period: "2017 ~ 2018",
    list: [
      "Object oriented programming (OOP)",
      "Front end web development",
      "Full stack web development",
      "Cloud computing",
      "databases (MSQL)",
      "User Experience and User Interface Design",
      "Mobile Application development"
    ]
  },
  {
    index: 2,
    organization: "University of New South Wales (UNSW)",
    logo: UNSW,
    degree: "Bachelor of Electrical Engineering",
    period: "2011 ~ 2015",
    list: [
      "Coursework in programming",
      "Microelectronics",
      "Energy system",
      "Control system",
      "Signal processing",
      "Strategic leadership and ethics."
    ]
  }
];
